<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-2">
      <h1 class="text-primary-1 fs-1 fw-bold">Students</h1>
      <button class="
          btn
          text-secondary-1
          border-secondary-1
          bg-hover-light-secondary-1
          py-2
          px-4
        " @click="toggleLeadModal">
        <i class="pi pi-plus me-2"></i>
        Add lead
      </button>
    </div>
    <data-table v-if="currentTable === 'basic-info'" :value="basicInfoTable.data" ref="tableStudentsBasicInfo"
      :rowHover="basicInfoTable.data.length > 0" :loading="basicInfoTable.isLoading" size="normal"
      responsiveLayout="scroll" :paginator="false" @sort="onSort($event)" exportFilename="students-basic-info" :rows="20"
      filterDisplay="menu" v-model:selection="selectEmails" @rowSelect="onSelectRow" @rowUnselect="onSelectRow"
      @rowSelectAll="onSelectAll" @rowUnSelectAll="unSelectAll" :filters="basicInfoFilters" :globalFilterFields="[
        'name',
        'last_name',
        'email',
        'tel',
        'code.dial_code',
        'age',
        'gender',
        'birth',
        'phone_code_id',
        'time_zone',
        'enrollment',
        'enrollment_date',
        'status',
      ]">
      <template #header>
        <div class="d-flex align-items-center justify-content-between mb-3">
          <div class="d-flex align-items-center gap-2">
            <!-- <MultiSelect :modelValue="basicInfoTable.selectedColumns" :options="columns" optionLabel="header"
              @update:modelValue="onChangeColumns" placeholder="Select Columns" style="width: 12.5rem" /> -->
            <options-table-component :tableOptions="tableOptions" :currentTable="currentTable"
              :changeTable="changeTable" />
            <div class="d-flex align-items-center gap-1">
              <!-- <input-text v-if="isBasicInfoSearch" v-model="find" placeholder="Search by name, email ..."
                class="rounded-0" /> -->
              <div class="p-inputgroup flex-1" v-if="isBasicInfoSearch">
                <InputText v-model="find" placeholder="Search by name, email..." class="rounded-0" />
                <Button icon="pi pi-search" @click="search" />
              </div>
              <i class="
                  pi
                  py-1
                  px-3
                  bg-primary-1
                  text-white
                  fs-5
                  icon-search
                  pointer
                  border-r-10
                  overflow-hidden
                " :class="isBasicInfoSearch ? 'pi-times' : 'pi-search'" @click="toggleBasicInfoSearch"></i>
            </div>
          </div>
          <div class="d-flex gap-3">
            <div class="field-outline-blue">
              <dropdown :options="optionsReports" optionLabel="text" optionValue="id" placeholder="Select report"
                :filter="true" v-model="basicInfoTable.currentOptionReport" class="bg-transparent" :showClear="true"
                @change="onChangeOptionReport" />
            </div>
            <div class="field-outline-blue w-100">
              <Calendar id="icon" class="w-100 bg-white calendar-small" v-model="dateRange" dateFormat="M dd"
                selectionMode="range" :manualInput="false" :showIcon="true" placeholder="MM/DD - MM/DD"
                :showButtonBar="true" @clear-click="clearDateRanges" />
            </div>
          </div>
        </div>
      </template>
      <template #empty> No students found. </template>
      <template #loading> Loading students data. Please wait. </template>
      <column selectionMode="multiple" headerStyle="width: 3rem"></column>

      <column v-for="(col, index) in basicInfoTable.header" :key="index" :sortable="col.sortable" :field="col.field"
        :showFilterMatchModes="false" | :header="col.name" style="min-width: 14rem">
        <template #body="{ data }">
          <p v-if="!customColumns.includes(col.field)">
            {{ data[col.field] }}
            {{ col.field === "name" ? data.full_name ?? "" : "" }}
          </p>

          <div class="px-3 py-1 w-content rounded-pill text-white" v-if="col.field === 'enrollment' && data.enrollment"
            :class="[enrollmentUsers[data?.enrollment]?.class]">
            {{ data.enrollment }}
          </div>
          <div class="px-3 py-1 w-content rounded-pill" v-if="col.field === 'tel' && data['tel']">
            {{ data.code ? data.code.dial_code ?? "" : "" }}
            {{ data[col.field] }}
          </div>

          <div class="d-flex align-items-center gap-1" v-if="col.field === 'name'">
            <button type="button" class="btn text-secondary-1 text-hover-secondary-1 p-0 pt-1 px-0" @click="goToPage({
              id: data['id'],
              name: 'admin-member-student-profile',
            })
              ">
              See profile
            </button>
            <span class="material-icons fs-5 text-secondary-1 pointer" @click="
              goToPage({
                id: data['id'],
                name: 'admin-member-student-edit-profile',
              })
              ">
              edit
            </span>
            <span v-if="data.enrollment !== 'Lead'" class="material-icons fs-5 text-secondary-1 pointer" @click="
              goToPage({
                id: data['id'],
                name: 'admin-member-student-billing',
              })
              ">
              monetization_on
            </span>
          </div>

          <div class="
              bg-white
              py-1
              px-3
              bl-shadow
              d-flex
              gap-2
              rounded-pill
              w-content
              align-items-center
            " v-if="col.field === 'status'">
            <div class="status" :class="status[data.status] ? status[data.status].class : 'bg-neutro-2'"></div>
            <span class="text-neutro-1" v-if="data.status">{{
              status[data.status] ? status[data.status].text : "Offline"
            }}</span>
            <span class="text-neutro-1" v-if="!data.status">Offline</span>
          </div>
        </template>


        <template #filter v-if="col.filter">
          <!-- <div class="d-block" v-if="col.field === 'enrollment'">
            <div class="d-flex align-items-center mb-1 gap-2">
              <Checkbox name="typeStudent" value="Lead" v-model="filterModel.value" />
              <label>Lead</label>
            </div>
            <div class="d-flex align-items-center mb-1 gap-2">
              <Checkbox name="typeStudent" value="Enrollment" v-model="filterModel.value" />
              <label>Enrollment</label>
            </div>
            <div class="d-flex align-items-center mb-1 gap-2">
              <Checkbox name="typeStudent" value="Drop" v-model="filterModel.value" />
              <label>Drop</label>
            </div>
            <div class="d-flex align-items-center mb-1 gap-2">
              <Checkbox name="typeStudent" value="Trial" v-model="filterModel.value" />
              <label>Trial</label>
            </div>
          </div> -->
          <div class="d-block" v-if="col.field === 'status'">
            <div class="d-flex align-items-center mb-1 gap-2">
              <Checkbox name="status" value="1" v-model="statusVal" />
              <label>Online</label>
            </div>
            <div class="d-flex align-items-center mb-1 gap-2">
              <Checkbox name="status" value="0" v-model="statusVal" />
              <label>Offline</label>
            </div>
          </div>
          <div class="d-block" v-if="col.field === 'gender'">
            <div v-for="(gen, index) in genderValues" :key="index" class="d-flex align-items-center mb-1 gap-2">
              <Checkbox name="status" :value="gen" v-model="gender" />
              <label>{{ index }}</label>
            </div>
          </div>
          <div class="d-block" v-if="col.field === 'age'">
            <div class="d-block">
              <input-text type="number" name="age" v-model="age" placeholder="Search by age" />
            </div>
          </div>
          <div class="d-block" v-if="col.field === 'level'">

            <div v-for="(lev, index) in getListings.levels.data" :key="index"
              class="d-flex align-items-center mb-1 gap-2">
              <Checkbox name="level" :value="lev.level_name_short" v-model="level" />
              <label>{{ lev.name }}</label>
            </div>
          </div>
          <div class="d-block" v-if="col.field === 'canceled'">
            <div class="d-block">
              <input-text type="number" name="cant_classes_cancelled" v-model="canceledVal"
                placeholder="Search by canceled class" />
            </div>
          </div>
          <div class="d-block" v-if="col.field === 'apply_discount_code'">
            <div class="d-block">
              <input-text type="number" name="code" v-model="codeVal" placeholder="Search by applied discount code " />
            </div>
          </div>
          <div class="d-block" v-if="col.field === 'video'">
            <div class="d-block">
              <input-text type="number" name="rewards_video" v-model="videoVal" placeholder="Search by rewards video" />
            </div>
          </div>
        </template>


        <template #filterclear="{ filterCallback }">
          <Button type="button" @click="() => {
            filterCallback()
            filterClear()
          }" severity="secondary">Clear</Button>
        </template>
        <template #filterapply="{ filterCallback }">
          <Button type="button" @click="() => {
            filterCallback()
            filterApply()
          }" severity="success">Apply</Button>
        </template>
      </column>
      <template #footer>
        <Paginator :rows="20" :totalRecords="basicInfoTable.meta?.total" page-link-size="3" @page="onPage($event)"
          :rowsPerPageOptions="[20, 30, 40, 50]" @update:rows="changeLimit($event)">
          <template #start>
            <div class="d-flex align-items-center justify-content-start gap-3">
              <Button class="p-button-rounded bg-primary-1 text-white border-0 m-0" icon="pi pi-send"
                :disabled="selectEmails.length === 0" @click="() => emailModal(true)" v-tooltip.top="'Send email'"
                style="width: 2.4rem" />
              <Button :disabled="selectEmails.length === 0" class="p-button-rounded bg-primary-1 text-white border-0 m-0"
                icon="pi pi-refresh" v-tooltip.top="'Reset password'" @click="() => cancelEmailResetModal(true)"
                style="width: 2.4rem" />
              <Button class="p-button-rounded bg-primary-1 text-white border-0 m-0" icon="pi pi-sort-alt"
                :disabled="loadingExport" v-tooltip.top="'Export report'" @click="() => exportCsv('students-basic-info')"
                style="width: 2.4rem" />
              <p v-if="selectEmails.length > 0">{{ selectEmails.length }} selected from {{ basicInfoTable.data.length }}
                records
              </p>
            </div>
          </template>
        </Paginator>
      </template>
    </data-table>
  </div>
  <export-excel class="btn btn-default d-none basic-students" :data="dataExcel" :fields="fieldsExcelBasicStudents"
    worksheet="My Worksheet" name="filename.xls">
  </export-excel>
</template>

<script>
import useStudents from "@/modules/admin/composables/Members/Students/useStudents";
import OptionsTableComponent from "@/modules/admin/components/Members/OptionsTableComponent";
import enrollmentUsers from "@/shared/data/enrollmentUsers";
import status from "@/shared/types/typesUserStatus";
import useResetEmail from "@/modules/admin/composables/Members/useResetEmail";
import useSendEmail from "@/modules/admin/composables/Members/useSendEmail";
import useMembers from "@/modules/admin/composables/Members/useMembers";
import formatDate from "@/shared/utils/dates/formatDate";
import { useStore } from "vuex";
import { ref, computed, onBeforeMount } from "vue";
import Paginator from 'primevue/paginator';
export default {
  name: "MemberStudentView",
  components: {
    OptionsTableComponent,
  },

  setup() {
    const customColumns = ["status", "enrollment", "tel", "code"];
    const { optionsReports, setInfoStatusStudents, defaultHeader, generateQuery } = useStudents()
    const store = useStore();

    onBeforeMount(async () => {
      let { currentOptionReport, header } =
        store.getters["admins/getMemberStudents"].tables.basicInfo;
      if (!currentOptionReport || currentOptionReport === "default") {
        store.commit("admins/setStudentsBasicInfo", { header: defaultHeader });
        await store.dispatch("admins/getStudents", generateQuery.value);
        setInfoStatusStudents();
      } else {
        const currentOption = optionsReports.find(
          (report) => report.id === currentOptionReport
        );
        if (currentOption) {
          store.dispatch("admins/getStudentsByReport", [{
            option: currentOptionReport,
          }, generateQuery.value]);
          header = header.filter((header) =>
            currentOption.fields.includes(header.field)
          );
          store.commit("admins/setStudentsBasicInfo", { header });
          setInfoStatusStudents();
        }
      }
    });

    const genderValues = {
      "Male": 1,
      "Female": 2,
      "Prefer not to say": 3
    }

    return {
      ...useStudents(),
      ...useMembers(),
      ...useSendEmail(),
      ...useResetEmail(),
      enrollmentUsers,
      status,
      formatDate,
      customColumns,
      genderValues
    };
  },
};
</script>
