const enrollmentUsers = {
  Trial: {
    class: "bg-accent-1",
    classText: "text-neutro-1",
  },
  Lead: {
    class: "bg-accent-6",
    classText: "text-neutro-1",
  },
  Enrollment: {
    class: "bg-secondary-1",
    classText: "text-white",
  },
  Drop: {
    class: "bg-secondary-0",
    classText: "text-white",
  },
};

export default enrollmentUsers;
